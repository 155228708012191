(function ($) {
    
    
    var user_id = $('#user_id').val();
    var school_id = $('#school_id').val();
    
    $('.cal_teach').click(function(){
        
        var $this = $(this);
        
        var date = $(this).attr('rel');
        //alert(date);
        //$( this ).toggleClass("red");
        //$( this ).children('p').removeClass("alert-success")
       
        $.ajax({
            url: '/account/calendar/update/' + user_id + '/' + date + '/', 
            success: function(result){
                $this.toggleClass("red");
                $this.children('p').removeClass("alert-success")
            }
        });
        
    });
    
    $('.cal_school').click(function(){ 
        
        var $this = $(this);
        
        if(!$(this).is('[class*="red"]')){
        
            var date = $(this).attr('rel');
            //alert(date);
            $.ajax({
                url: '/account/bookTeacher/' + user_id + '/' + school_id + '/' + date + '/', 
                success: function(result){
                    $this.toggleClass("green");
                }
            });
        }
        
    });
    
    $('.cal_teach p').click(function(e) {
        e.stopPropagation();
    });
    
    $('.cancel_booking').click(function(){
        
        var calendar_id = $(this).attr('rel');
        
        if (confirm('Are you sure you want to cancel this event?')) {
            
            $.ajax({
                url: '/account/deleteCalendarEvent/' + calendar_id , 
                success: function(result){
                    
                    alert('That event has been cancelled.');
                    location.reload();
                }
            });
        }
        
    });
    
    $('.rebook_booking').click(function(){
        
        var calendar_id = $(this).attr('rel');
        
        if (confirm('Are you sure you want to re-book this event?')) {
            
            $.ajax({
                url: '/account/rebookCalendarEvent/' + calendar_id , 
                success: function(result){
                    
                    alert('That event has been re-booked.');
                    location.reload();
                }
            });
        }
        
    });
    
})(jQuery, window);
